<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filter_search"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_search"
              v-model="tableCall.filter.search"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableCall"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableCall.perPage"
            :current-page="tableCall.currentPage"
            :items="tableCallProvider"
            :fields="tableCall.fields"
            :sort-by.sync="tableCall.sortBy"
            :sort-desc.sync="tableCall.sortDesc"
            :sort-direction="tableCall.sortDirection"
            :filter="tableCall.filter"
            :filter-included-fields="tableCall.filterOn"
            :busy="tableCall.busy"
          >
            <template #cell(index)="row">
              {{ tableCall.currentPage * tableCall.perPage - tableCall.perPage + (row.index + 1) }}
            </template>

            <template #cell()="row">
              <div class="text-wrap text-md-nowrap">
                {{ row.value }}
              </div>
            </template>

            <template #cell(active)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(amount)="row">
              <div
                class="text-md-nowrap text-right"
                style="min-width: 100px !important;"
              >
                {{ row.value }}
              </div>
            </template>

            <template #cell(action)="row">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  v-if="row.item.payload"
                  size="sm"
                  :variant="row.detailsShowing ? 'danger' : 'primary' "
                  @click="row.toggleDetails"
                >
                  {{ row.detailsShowing ? 'Hide' : 'Show' }} Payload
                </b-button>
              </div>
            </template>

            <template #row-details="row">
              <b-form-group
                label="Payload"
                label-class="font-weight-bolder"
                class="mb-2 px-5"
              >
                <vue-json-pretty
                  :data="row.item.payload"
                />
              </b-form-group>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableCall.perPage"
            :options="tableCall.pageOptions"
            size="sm"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableCall.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableCall.totalRows"
            :per-page="tableCall.perPage"
          />
        </b-col>
      </b-row>
    </b-container>

  </b-container>
</template>

<script>
// import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { SAdminWebhook } from '@/services'
import formatter from '@/mixins/formatter'
import Ripple from 'vue-ripple-directive'
import misc from '@/mixins/misc'

export default {
  name: 'AdminWebhookCalls',

  middleware: ['auth', 'admin'],

  directives: {
    Ripple
  },

  mixins: [misc, formatter],

  metaInfo: () => ({
    title: 'Calls'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      webhook: {
        payload: null
      },
      tableCall: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 4, key: 'action', class: 'text-center min-w-150px' },
          { mobile: 1, key: 'created_at', class: 'min-w-200px', formatter: this.dateTimeFormatter, sortable: true },
          { mobile: 2, key: 'name', class: 'min-w-200px' }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableCallProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableCall.busy = true
      return await SAdminWebhook.getCalls(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search
        })
      ).then(({ data }) => {
        this.tableCall.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableCall.totalRows = 0
        return []
      }).finally(() => {
        this.tableCall.busy = false
      })
    }
  }
}
</script>
